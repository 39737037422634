





import Vue from 'vue';
// import moment from 'moment';
import _, { isString } from 'lodash';
// import qs from 'qs';
import { Context } from '@nuxt/types';
import { ResponseCode } from '~/util/constant';
import { handleApiError, handleNodeServerError } from '~/util/common';
// import { $cookies } from '~/util/api';
// import { TraceCode } from '~/util/trace';

export default Vue.extend({
  filters: {},
  components: {},
  // 校验params参数
  //   validate(ctx: Context) {
  //     const { path } = ctx.route;
  //     const { key } = ctx.query;
  //     console.log('24-24', key);
  //     if (key === '12rwwsfytrsrfrtewerawgrttuutirtresfbvczssf') {
  //       return true;
  //     }
  //     if ((key ?? '') === '') {
  //       throw new Error('业务异常!');
  //       // return ctx.error({
  //       //     message: '展会不存在！',
  //       //     path: '',
  //       //     statusCode: 404,
  //       // });
  //     }
  //     throw new Error('业务异常!');
  //   },
  async asyncData(ctx: any) {
    try {
      const { key } = ctx.query;
      if (key === '12rwwsfytrsrfrtewerawgrttuutirtresfbvczssf') {
        // ctx.store.commit('global/setServiceStatus', false);
        // console.log('43-43', ctx.store.state.global);
      } else {
        // console.log('43-44', ctx.store.state.global);
        return ctx.error({
          message: '参数错误',
          path: '',
          statusCode: 500,
        });
      }
      //   const lang = _.get(ctx, 'query.lang', null);
      //   let langApi = 'cn';
      //   if (lang == 'zh') langApi = 'cn';
      //   if (lang == 'en') langApi = 'en';

      //   let apiErrorList: any = [];
      //   let requestList: any[] = [];

      //   requestList = [
      //     ctx.$axios.get('/api/sys/health/check'), //
      //   ];

      //   const result: any[] = await Promise.all(requestList);
      //   const [
      //     newsListRes, // 最新资讯
      //   ] = result;

      //   if (newsListRes.code === ResponseCode.SUCCESS) {
      //   } else {
      //     apiErrorList.push({
      //       code: newsListRes.code,
      //       message: newsListRes.message,
      //     });
      //     handleNodeServerError(ctx, JSON.stringify(apiErrorList));
      //     return ctx.error({
      //       message: JSON.stringify({
      //         code: newsListRes.code,
      //         message: newsListRes.message,
      //       }),
      //       path: ctx.route.fullPath,
      //       statusCode: 500,
      //     });
      //   }

      return {
        key,
      };
    } catch (e) {
      ctx.error(e as any);
    }
  },
  data(): Record<string, any> {
    return {
      message: '',
      keyword: '',
      apiErrorList: [],
    };
  },
  computed: {},
  created(): void {},
  mounted() {
    // handleApiError(this.apiErrorList, '/system/error');
  },
  methods: {},
  // head(): Record<string, any> {

  // },
});
